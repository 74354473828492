import React from 'react';

import './shopify.side.scss';
import LogoWide from '../images/logowide';
import { Link } from 'gatsby';

interface ShopifySideMenuProps {
    location: Location;
}

const ApiSideMenu: React.FC<ShopifySideMenuProps> = ({ location }) => {

    return (
        <div className="ApiSideMenu SIDE_MENU">
            <div className="LOGO">
                <LogoWide format="icon" />
            </div>
            <div className="section">
                <p><Link to="/api/referencia" activeClassName="active">
                    Funcionalidad
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/api/referencia/clientes-agregar" activeClassName="active">
                    Clientes
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/referencia/clientes-agregar" activeClassName="active">
                        Agregar cliente
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-certificados" activeClassName="active">
                        Certificado Cliente
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-llaves" activeClassName="active">
                        Llave del cliente
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-editar" activeClassName="active">
                        Editar cliente
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-borrar" activeClassName="active">
                        Borrar cliente
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-buscar" activeClassName="active">
                        Buscar clientes
                    </Link></p>
                    <p><Link to="/api/referencia/clientes-billing" activeClassName="active">
                        Billing cliente
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/api/referencia/cfdi-minimum" activeClassName="active">
                    Operaciones CFDI
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/referencia/cfdi-minimum" activeClassName="active">
                        Emitir Factura (Mínimo)
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-maximum" activeClassName="active">
                        Emitir Factura (Completo)
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-cancel" activeClassName="active">
                        Cancelar Factura
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-find" activeClassName="active">
                        Buscar Facturas
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/api/referencia/cfdi-facturar-pagos" activeClassName="active">
                    Complementos
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/referencia/cfdi-facturar-pago"  activeClassName="active">
                        Facturar Pagos
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-complemento-pago"  activeClassName="active">
                        Complemento Pagos
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-complemento-notarios" activeClassName="active">
                        Complemento Notarios
                    </Link></p>
                    <p><Link to="/api/referencia/cfdi-complemento-transporte" activeClassName="active">
                        Complemento Transporte
                    </Link></p>
                </div>
            </div>
        </div>
    )
}

export default ApiSideMenu;