import React from 'react';

import './shopify.side.scss';
import LogoWide from '../images/logowide';
import { Link } from 'gatsby';

interface ShopifySideMenuProps {
    location: Location;
}

const ApiCatalogoSideMenu: React.FC<ShopifySideMenuProps> = ({ location }) => {

    return (
        <div className="ApiSideMenu SIDE_MENU">
            <div className="LOGO">
                <LogoWide format="icon" />
            </div>
            <div className="section">
                <p><Link to="/api/catalogo" activeClassName="active">
                    Funcionalidad
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/api/catalogo/catalogo-regimenfiscal" activeClassName="active">
                    Catálogos generales
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/catalogo/catalogo-regimenfiscal" activeClassName="active">
                        Regimen Fiscal
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-usocfdi" activeClassName="active">
                        Uso CFDI
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-formaspago" activeClassName="active">
                        Formas de pago
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-productoservicios" activeClassName="active">
                        Productos o servicios
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-claveunidad" activeClassName="active">
                        Clave de unidad
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-impuestos" activeClassName="active">
                        Impuestos
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-relacion" activeClassName="active">
                        Relación facturas
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/api/catalogo/catalogo-paises" activeClassName="active">
                    Catálogos para notarios
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/catalogo/catalogo-paises" activeClassName="active">
                        Paises
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-estados" activeClassName="active">
                        Estados
                    </Link></p>
                    <p><Link to="/api/catalogo/catalogo-inmuebles" activeClassName="active">
                        Inmuebles
                    </Link></p>
                </div>
                <p><Link to="/api/catalogo/catalogo-blacklist" activeClassName="active">
                    Lista 69B
                </Link></p>
                <div className="subsection">
                    <p><Link to="/api/catalogo/catalogo-blacklist" activeClassName="active">
                        Blacklist 69B
                    </Link></p>
                </div>
            </div>
        </div>
    )
}

export default ApiCatalogoSideMenu;