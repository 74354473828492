import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import './header.ecommerce.scss';
import LogoWide from "./images/logowide";

interface HeaderEcomProps {
  location: Location;
  siteTitle: string;
}

const HeaderEcommerce: React.FC<HeaderEcomProps> = ({ siteTitle, location }) => (
  <header id="eCommerce">
    <div>
      <Link to="/">
        <div className="logos">
          <LogoWide format={'icon'} />
          <LogoWide format={'text'} />
        </div>
      </Link>
    </div>
    <div className="rightLinks">
      <Link to="/ecommerce/shopify" activeClassName="active">
        Shopify
        </Link>
      <Link to="/ecommerce/mercadolibre" activeClassName="active">
        MercadoLibre
        </Link>
      <Link to="/ecommerce/amazon" activeClassName="active">
        Amazon
        </Link>
    </div>
  </header>
)


export default HeaderEcommerce
