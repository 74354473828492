/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"
import HeaderApi from "./header.api.tsx"
import HeaderEcommerce from "./header.ecommerce.tsx"
import ShopifySideMenu from "./sidemenu/shopify.side"
import LogoWide from "./images/logowide"
import MercadoLibreSideMenu from "./sidemenu/mercadolibre.side"
import ApiSideMenu from "./sidemenu/api.reference.side"
import ApiCatalogoSideMenu from "./sidemenu/api.catalogo.side"
import AmazonSideMenu from "./sidemenu/amazon.side"


const FooterFull = () => {
  return (
    <footer>
      <div className="_logo">
        <LogoWide format={'icon'} />
        <LogoWide format={'text'} />
      </div>
      <div className="_linksWrap">
        <div className="links">
          <a href="/api/referencia">API</a>
          <a href="/ecommerce/shopify">Shopify</a>
          <a href="/ecommerce/mercadolibre">MercadoLibre</a>
          <a href="/ecommerce/amazon">Amazon</a>
        </div>
        <div className="links">
          <a href="https://fiscalpop.com/">Crear cuenta</a>
          <a href="https://fiscalpop.com/">Tutoriales</a>
          <a href="/">Documentación</a>
        </div>
        <div className="links">
          <a href="mailto:contacto@fiscalpop.com">Contáctanos</a>
          <a href="https://fiscalpop.com/policies">Políticas de uso</a>
          <a href="https://fiscalpop.com/privacy">Aviso de privacidad</a>
        </div>
      </div>
      <div className="ssLinks">

      </div>
      <div className="signature">
        © {new Date().getFullYear()}, Fiscal POP
      </div>
    </footer>
  )
}

const Layout = ({ children, noHeader, isEcommerce, isApi, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  // Side menu elements
  const [openMenu, setOpenMenu] = useState(false);

  const sideMenuByLocation = () => {
    if (!location) {
      return ''
    }
    if (location.pathname.includes('/ecommerce/shopify')) {
      return <ShopifySideMenu location={location} />
    }
    else if (location.pathname.includes('/ecommerce/amazon')) {
      return <AmazonSideMenu location={location} />
    }
    else if (location.pathname.includes('/ecommerce/mercadolibre')) {
      return <MercadoLibreSideMenu location={location} />
    }
    else if (location.pathname.includes('/api/referencia')) {
      return <ApiSideMenu location={location} />
    } else if (location.pathname.includes('/api/catalogo')) {
      return <ApiCatalogoSideMenu location={location} />
    }
  }

  const layoutClass = () => {
    if (!location) {
      return ''
    }
    if (location.pathname.includes('/ecommerce/shopify')) {
      return 'shopify';
    } else if (location.pathname.includes('/ecommerce/mercadolibre')) {
      return 'mercadolibre';
    }
    else if (location.pathname.includes('/api/referencia')) {
      return 'api-referencia';
    }
    else if (location.pathname.includes('/api/catalogo')) {
      return 'api-catalogo';
    }
  }

  const hasSideMenu = !!sideMenuByLocation();

  return (
    <>
      {
        noHeader ?
          ''
          :
          (isEcommerce ? <HeaderEcommerce siteTitle={data.site.siteMetadata.title} location={location} /> : (isApi ? <HeaderApi siteTitle={data.site.siteMetadata.title} location={location} /> : <Header siteTitle={data.site.siteMetadata.title} location={location} />))
      }
      {
        hasSideMenu ?
          <div id="LAYOUT_BASE" className={`sideMenu ${layoutClass()} ${openMenu ? 'isOpen' : ''}`}>
            {sideMenuByLocation()}
            <div className="_sideTrigger" >
              <div className="_sideTriggerOpen" onClick={() => setOpenMenu(true)}/>
              <div className="_sideTriggerOverlay" onClick={() => setOpenMenu(false)}/>
            </div>
            <div className="Layout_Container">
              <main>{children}</main>
              <FooterFull />
            </div>
          </div>
          :
          <div id="LAYOUT_BASE">
            <main>{children}</main>
            <FooterFull />
          </div>
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  isEcommerce: PropTypes.bool,
  isApi: PropTypes.bool,
  location: PropTypes.object
}

export default Layout
