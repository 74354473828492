import React from 'react';

import './shopify.side.scss';
import LogoWide from '../images/logowide';
import { Link } from 'gatsby';

interface ShopifySideMenuProps {
    location: Location;
}

const MercadoLibreSideMenu: React.FC<ShopifySideMenuProps> = ({ location }) => {

    return (
        <div className="ShopifySideMenu SIDE_MENU">
            <div className="LOGO">
                <LogoWide format="icon" />
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre" activeClassName="active">
                    Funcionalidad
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre/certificados" activeClassName="active">
                    Mi configuración
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/mercadolibre/certificados" activeClassName="active">
                        Certificados
                    </Link></p>
                    <p><Link to="/ecommerce/mercadolibre/claves-default" activeClassName="active">
                        Claves default
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre/config-autofact" activeClassName="active">
                    Activar facturación
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/mercadolibre/config-autofact" activeClassName="active">
                        Configurar Link de autofacturación
                    </Link></p>
                    <p><Link to="/ecommerce/mercadolibre/link-comprador" activeClassName="active">
                        Proceso de tu comprador
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre/facturacion-manual" activeClassName="active">
                    Facturar ódenes
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/mercadolibre/facturacion-manual" activeClassName="active">
                        Facturación manual
                    </Link></p>
                    <p><Link to="/ecommerce/mercadolibre/facturacion-reembolso" activeClassName="active">
                        Facturación de Reembolsos
                    </Link></p>
                    <p><Link to="/ecommerce/mercadolibre/facturacion-global" activeClassName="active">
                        Facturación global
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre/productos" activeClassName="active">
                    Mis productos
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/mercadolibre/productos"  activeClassName="active">
                        Configurar impuestos
                    </Link></p>
                    <p><Link to="/ecommerce/mercadolibre/productos-multiple" activeClassName="active">
                        Claves SAT
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/mercadolibre/cancelar-facturas" activeClassName="active">
                    Mis Facturas
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/mercadolibre/cancelar-facturas" activeClassName="active">
                        Cancelar facturas
                    </Link></p>
                    <p><Link  to="/ecommerce/mercadolibre/reporte-facturas" activeClassName="active">
                        Reporte de facturación
                    </Link></p>
                </div>
            </div>
        </div>
    )
}

export default MercadoLibreSideMenu;