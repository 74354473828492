import React from 'react';

import './shopify.side.scss';
import LogoWide from '../images/logowide';
import { Link } from 'gatsby';

interface ShopifySideMenuProps {
    location: Location;
}

const ShopifySideMenu: React.FC<ShopifySideMenuProps> = ({ location }) => {

    return (
        <div className="ShopifySideMenu SIDE_MENU">
            <div className="LOGO">
                <LogoWide format="icon" />
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify" activeClassName="active">
                    Funcionalidad
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/certificados" activeClassName="active">
                    Mi configuración
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/certificados" activeClassName="active">
                        Certificados
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/claves-default" activeClassName="active">
                        Claves default
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/activar-facturacion" activeClassName="active">
                    Activar facturación
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/agregar-pagina-facturacion" activeClassName="active">
                        Agregar página de facturación
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/facturacion-en-checkout" activeClassName="active">
                        Facturación en checkout
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/post-facturacion" activeClassName="active">
                        ¿Que ocurre al facturar en línea?
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/facturacion-sencilla" activeClassName="active">
                    Facturar ódenes
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/facturacion-sencilla" activeClassName="active">
                        Facturación sencilla
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/facturacion-multiple" activeClassName="active">
                        Facturación múltiple
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/facturacion-global" activeClassName="active">
                        Facturación global
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/facturacion-reembolso" activeClassName="active">
                        Facturación de Reembolsos
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/configurar-impuestos" activeClassName="active">
                    Mis productos
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/configurar-impuestos"  activeClassName="active">
                        Configurar impuestos
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/claves-sat" activeClassName="active">
                        Claves SAT
                    </Link></p>
                    <p><Link to="/ecommerce/shopify/claves-pedimentos" activeClassName="active">
                        Pedimentos
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/mis-clientes" activeClassName="active">
                    Mis clientes
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/mis-clientes" activeClassName="active">
                        ¿Cómo funciona?
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/shopify/cancelar-facturas" activeClassName="active">
                    Mis Facturas
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/shopify/cancelar-facturas" activeClassName="active">
                        Cancelar facturas
                    </Link></p>
                    <p><Link  to="/ecommerce/shopify/reporte-facturas" activeClassName="active">
                        Reporte de facturación
                    </Link></p>
                </div>
            </div>
        </div>
    )
}

export default ShopifySideMenu;