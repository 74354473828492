import React from 'react';

import './shopify.side.scss';
import LogoWide from '../images/logowide';
import { Link } from 'gatsby';

interface ShopifySideMenuProps {
    location: Location;
}

const AmazonSideMenu: React.FC<ShopifySideMenuProps> = ({ location }) => {

    return (
        <div className="ShopifySideMenu SIDE_MENU">
            <div className="LOGO">
                <LogoWide format="icon" />
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon" activeClassName="active">
                    Funcionalidad
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/register" activeClassName="active">
                    Registar cuenta
                </Link></p>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/certificados" activeClassName="active">
                    Mi configuración
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/amazon/certificados" activeClassName="active">
                        Certificados
                    </Link></p>
                    <p><Link to="/ecommerce/amazon/claves-default" activeClassName="active">
                        Claves default
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/config-autofact" activeClassName="active">
                    Como facturar
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/amazon/config-autofact" activeClassName="active">
                        Proceso de facturación
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/facturacion-manual" activeClassName="active">
                    Facturar ódenes
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/amazon/facturacion-manual" activeClassName="active">
                        Facturación manual
                    </Link></p>
                    <p><Link to="/ecommerce/amazon/facturacion-reembolso" activeClassName="active">
                        Facturación de Reembolsos
                    </Link></p>
                    <p><Link to="/ecommerce/amazon/facturacion-global" activeClassName="active">
                        Facturación global
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/productos" activeClassName="active">
                    Mis productos
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/amazon/productos"  activeClassName="active">
                        Configurar impuestos
                    </Link></p>
                    <p><Link to="/ecommerce/amazon/productos-multiple" activeClassName="active">
                        Claves SAT
                    </Link></p>
                </div>
            </div>
            <div className="section">
                <p><Link to="/ecommerce/amazon/cancelar-facturas" activeClassName="active">
                    Mis Facturas
                </Link></p>
                <div className="subsection">
                    <p><Link to="/ecommerce/amazon/cancelar-facturas" activeClassName="active">
                        Cancelar facturas
                    </Link></p>
                    <p><Link  to="/ecommerce/amazon/reporte-facturas" activeClassName="active">
                        Reporte de facturación
                    </Link></p>
                </div>
            </div>
        </div>
    )
}

export default AmazonSideMenu;