import React from 'react';

interface LogoProps {
    format: 'all' | 'icon' | 'text'
}

const LogoWide: React.FC<LogoProps> = ({ format }: LogoProps) => {

    const viewBoxDimensions = () => {
        if (format === 'all') {
            return '0 0 616.04 311.71'
        } else if (format === 'icon') {
            return '240 18 155 155'
        } else {
            return '36 196 562 77'
        }
    }

    return (
        <svg id="_wideLogo" className={format} xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox={viewBoxDimensions()} xmlSpace="preserve">
            <g>
                {
                    format === 'all' || format === 'text' ?
                        <>
                            <g>
                                <g>
                                    <g>
                                        <path className="st0" d="M37.06,199.66h53.55c0,0-2.57,16.26-12.54,16.26s-22.45,0-22.45,0v11.38h25.95c0,0,0.78,15.4-10.62,15.4      s-15.33,0-15.33,0s0,4.05,0,14.02s-18.55,9.88-18.55,9.88V199.66z"></path>
                                        <path className="st0" d="M96.65,199.66c0,0,18.65,0.04,18.65,10.02c0,9.98,0,56.92,0,56.92s-18.65-1.33-18.65-11.31      S96.65,199.66,96.65,199.66z"></path>
                                        <path className="st0" d="M125.4,256.65l10.33-12.34c6.6,5.26,13.96,8.03,21.71,8.03c4.97,0,7.65-1.72,7.65-4.59v-0.19      c0-2.77-2.2-4.3-11.28-6.41c-14.25-3.25-25.25-7.27-25.25-21.04v-0.19c0-12.43,9.85-21.42,25.91-21.42      c11.38,0,20.27,3.06,27.54,8.89l-9.28,13.1c-6.12-4.3-12.81-6.6-18.74-6.6c-4.49,0-6.69,1.91-6.69,4.3v0.19      c0,3.06,2.29,4.4,11.57,6.5c15.4,3.35,24.96,8.32,24.96,20.85v0.19c0,13.67-10.8,21.8-27.06,21.8      C144.91,267.74,133.63,264.01,125.4,256.65z"></path>
                                        <path className="st0" d="M187.92,233.32v-0.19c0-19.51,15.01-34.81,35.28-34.81c13.67,0,22.47,5.74,28.4,13.96l-13.96,10.8      c-3.83-4.78-8.22-7.84-14.63-7.84c-9.37,0-15.97,7.94-15.97,17.69v0.19c0,10.04,6.6,17.88,15.97,17.88      c6.98,0,11.09-3.25,15.11-8.13l13.96,9.94c-6.31,8.7-14.82,15.11-29.64,15.11C203.31,267.94,187.92,253.3,187.92,233.32z"></path>
                                        <path className="st0" d="M302.27,239.83l-7.46-19.03l-7.55,19.03c0,0-3.9,9.39-7.17,19.36c-3.23,9.86-22.57,7.03-22.57,7.03      l28.5-67.42h17.88l28.5,67.42c0,0-18.1,1.52-22.37-7.03C305.75,250.64,302.27,239.83,302.27,239.83z"></path>
                                        <path className="st0" d="M340.26,199.66c0,0,18.55-1.38,18.55,11.45s0,39.23,0,39.23s2.83,0,17.08,0      c14.25,0,15.33,16.26,15.33,16.26h-50.97V199.66z"></path>
                                    </g>
                                    <path className="st0" d="M398.02,199.66h28.69c16.92,0,27.83,8.7,27.83,23.52v0.19c0,15.78-12.14,24.1-28.78,24.1h-9.18     c0,0,0-0.92,0,9.15c0,10.07-18.55,9.98-18.55,9.98V199.66z M425.37,232.94c6.6,0,10.61-3.44,10.61-8.7v-0.19     c0-5.74-4.02-8.8-10.71-8.8h-8.7v17.69H425.37z"></path>
                                    <path className="st0" d="M460.5,233.32v-0.19c0-19.22,15.49-34.81,36.15-34.81c20.65,0,35.96,15.4,35.96,34.62v0.19     c0,19.22-15.49,34.81-36.15,34.81S460.5,252.54,460.5,233.32z M513.67,233.32v-0.19c0-9.66-6.98-18.07-17.21-18.07     c-10.14,0-16.93,8.22-16.93,17.88v0.19c0,9.66,6.98,18.07,17.12,18.07C506.88,251.2,513.67,242.98,513.67,233.32z"></path>
                                    <path className="st0" d="M540.3,199.66h28.69c16.93,0,27.83,8.7,27.83,23.52v0.19c0,15.78-12.15,24.1-28.78,24.1h-9.18     c0,0,0-0.92,0,9.15c0,10.07-18.55,9.98-18.55,9.98V199.66z M567.65,232.94c6.6,0,10.61-3.44,10.61-8.7v-0.19     c0-5.74-4.02-8.8-10.71-8.8h-8.7v17.69H567.65z"></path>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st0" d="M575.09,259.75h3.66c1.18,0,2,0.31,2.52,0.84c0.45,0.44,0.69,1.04,0.69,1.8v0.02c0,1.19-0.63,1.97-1.59,2.38     l1.85,2.7h-2.48l-1.56-2.35h-0.94v2.35h-2.15V259.75z M578.66,263.47c0.73,0,1.15-0.35,1.15-0.92v-0.02     c0-0.61-0.44-0.92-1.16-0.92h-1.41v1.86H578.66z"></path>
                                </g>
                                <path className="st0" d="M578.18,271.79c-4.31,0-7.82-3.51-7.82-7.82c0-4.31,3.51-7.82,7.82-7.82s7.82,3.51,7.82,7.82    C586,268.28,582.49,271.79,578.18,271.79z M578.18,257.16c-3.76,0-6.81,3.06-6.81,6.81c0,3.76,3.06,6.81,6.81,6.81    s6.81-3.06,6.81-6.81C584.99,260.21,581.93,257.16,578.18,257.16z"></path>
                            </g>
                        </>
                        : ''
                }
                {
                    format === 'all' || format === 'icon' ?
                        <g>
                            <path className="st0" d="M316.94,22c-40.24,0-72.85,32.62-72.85,72.85c0,40.24,32.62,72.85,72.85,72.85s72.85-32.62,72.85-72.85    C389.79,54.62,357.17,22,316.94,22z M276.52,82.39v-0.15c0-12.42,8.22-18.98,19.48-18.98h6.21v-4.7    c0-7.93,12.56-10.98,12.56-10.98v30.79l-18.51,0.09c-4.47,0-7.34,0.21-7.34,4.35v0.15c0,4.52,2.87,5.68,7.4,5.68h5.73v0.04h12.72    v12.24h-19.42C283.9,100.92,276.52,94.06,276.52,82.39z M314.91,139.66c0,0-12.56-3.05-12.56-10.91c0-5.16,0-6.41,0-6.67    c0,0-2.66,0-10.38,0c-7.72,0-7.19-12.12-7.19-12.12h1.42v0.05h28.71V139.66z M321.36,47.58c0,0,12.56,3.05,12.56,10.91    c0,7.86,0,8.54,0,8.54s2.66,0,10.38,0c7.72,0,7.19,12.12,7.19,12.12h-1.42V79.1h-28.71V47.58z M359.76,106.87    c0,12.42-8.22,18.98-19.48,18.98h-6.21v2.83c0,7.93-12.56,10.98-12.56,10.98v-28.92l18.51-0.09c4.47,0,7.34-0.21,7.34-4.35v-0.15    c0-4.52-2.87-5.68-7.4-5.68h-5.73v-0.04h-12.72V88.2h19.42c11.46,0,18.84,6.85,18.84,18.52V106.87z"></path>
                        </g>
                        : ''
                }
            </g>
        </svg>
    )
}

export default LogoWide;